<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <!-- Colaborador -->
              <div class="mt-5">
                <div>
                  <multiple-select labelField="nome" :options="employeeList" :id-field="'id_employee'" :placeholder="this.$i18n.t('form.employee.label')" :fetchOptions="employeeFetchOptions" :value="filters.filEmployees" />
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.task.status.active') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.task.status.inactive')
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div :class="processTableSize">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="processEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="Numero_Processo"
                            :detail-row-component="detail"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <a  class="btn btn-link btn-padding" :title="$t('actions.add_info_to_alerts')"
                          v-on:click="addInfoToAlerts(props.rowData)">
                        <i class="text-primary  fa fa-info font-20"></i>
                      </a>

                      <a  v-if="filter.id_status == 1" class="btn btn-link btn-padding" :title="$t('actions.remove_from_alerts')"
                         v-on:click="removeFromAlerts(props.rowData)">
                        <i class="text-primary  ft-user-check font-20"></i>
                      </a>
                      <a  v-else class="btn btn-link btn-padding" :title="$t('actions.add_to_alerts')"
                         v-on:click="addToAlerts(props.rowData)">
                        <i class="text-primary  ft-user-x font-20"></i>
                      </a>
                      <a  class="btn btn-link btn-padding" :title="$t('actions.log')"
                          v-on:click="showLogs(props.rowData)">
                        <i class="text-primary  ft-file-text font-20"></i>
                      </a>
                      <a v-if="!isOpened(props.rowData)"  class="btn btn-link btn-padding" :title="$t('actions.info_show')"
                         v-on:click="onTogleDetails(props.rowData)">

                        <i class="text-primary  ft-plus-circle font-20"></i>
                      </a>
                      <a v-else class="btn btn-link btn-padding" :title="$t('actions.info_hide')"
                         v-on:click="onTogleDetails(props.rowData)">

                        <i class="text-primary  ft-minus-circle font-20"></i>
                      </a>
                    </template>
                  </vuetable>
                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// Services
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import VModal from 'vue-js-modal'
import UpdateStatus from '../../UpdateStatus'
import AddInfo from '../../AddInfo'
import ShowLogs from '../../ShowLogs'
import Detail from './Detail.vue'

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'ReportAlertsProcessosPendenciaSeguro',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.alerts.title') + ' - %s'
    }
  },
  data () {
    return {
      detail: Detail,
      tipoAlerta: '09201',
      customerList: [],
      employeeList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processTableSize: 'col-12',
      processDetail: false,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/report/alert/processos-free-time-compra-menor-venda',
      multiSort: true,
      activeFilterList: {
        internal_code: null,
        id_status: null,
        employees: null
      },
      filter: {
      },
      sortOrder: [
        { field: 'Data_Abertura_Processo', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      disable: {
        expo: false,
        impo: false,
        nac: false,
        mar: false,
        air: false,
        land: false
      }
    }
  },
  components: {
    MultipleSelect,
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer
  },
  beforeMount () {
    if (this.$route.params.process) {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
    }
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    isOpened (row) {
      return this.$refs.vuetable.isVisibleDetailRow(row.Numero_Processo)
    },
    onTogleDetails (row) {
      this.$refs.vuetable.toggleDetailRow(row.Numero_Processo)
    },
    onCloseModal () {
      this.$refs.vuetable.refresh()
    },
    buildFilters () {
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      this.filter.id_status = 1
    },
    handleLoadError (response) {
    },
    // Loader
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    applyFilter () {
      this.employeeList = []

      this.filters.filProcesso = this.$refs.internal_code.value

      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel
      this.filter.employees = []

      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      // this.filter.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null
      this.filters.filCliente = []
      this.filters.filEmployees = []

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.process.status.open'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.process.status.awaiting_shipping'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.process.status.boarded'),
          'color': '#c59b6b'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.process.status.landed'),
          'color': '#723886'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.process.status.paided'),
          'color': '#0074a0'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.process.status.finalized'),
          'color': '#5ec65f'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.hideProcessDetail()
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
    //  this.removeFromAlerts(row.data)
    },
    hideProcessDetail () {
      this.$jQuery('tr.active').removeClass('active')
      this.processDetail = false
      this.processTableSize = 'col-12'
    },
    showLogs (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      this.$modal.show(ShowLogs, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        }
      )
    },
    addInfoToAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      this.$refs.vuetable.hideDetailRow(rowData.Numero_Processo)

      let me = this
      this.$modal.show(AddInfo, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    addToAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      rowData.situacao = 1
      let me = this
      this.$modal.show(UpdateStatus, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    removeFromAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      rowData.situacao = 2
      let me = this
      this.$modal.show(UpdateStatus, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      }).catch(() => {
        _this.isLoading = false
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    fieldsTable () {
      return [
        {
          name: 'Numero_Processo',
          title: this.$i18n.t('form.process.internal_code'),
          sortField: 'Numero_Processo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Cliente',
          title: this.$i18n.t('form.process.customer_name'),
          sortField: 'Cliente',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Responsavel',
          title: this.$i18n.t('report.responsavel'),
          sortField: 'Responsavel',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Data_Abertura_Processo',
          title: this.$i18n.t('form.process.data_abertura'),
          sortField: 'Data_Abertura_Processo',
          formatter: (value) => this.$util.formatDate(value, undefined, ' - ')
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions wrap-actions-large',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    process () {
      return this.$route.params.process || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
  .wrap-actions-large{
    width: 300px;
  }
</style>
